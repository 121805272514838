*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  }
html{
    font-size: 62.5%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 100% !important;
    min-height: 100% !important;
}
body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    height: 100% !important;
    min-height: 100vh !important;
    background-color: var(--main-background);
    transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out;
}
