/* // @import '../../styles/config.scss'; */

.loginContainer{
    height: 100vh;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/gp-login-bg.png');
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 2;
}
.loginContainer:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(45,45,45,0.5105392498796393) 0%, rgba(141,133,198,0.8018557764902836) 70%, rgba(143,125,193,0.7990546560421043) 100%);
}
.logoContainer{
    display: flex;
    align-items: center;
    z-index: 3;
    position: relative;
    top: -2rem;
}
.loginLogo{
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-right: 2rem;
}
.loginLogoText{
    font-size: 4rem;
    font-weight: 500;
    color: white;
    margin: 0;
}
.loginForm{
    background: white;
    border-radius: 0.8rem;
    padding: 2rem 4rem;
    z-index: 3;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    position: relative;
}
.verificationErrorForm{
    display: flex;
    flex-direction: column;
    color: #172B4D;
    width: 90%;
}
.text{
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: #172B4D;
}
.formHeaderText{
    @extend .text;
    font-weight: bold;
    font-size: 2.6rem;
    margin-bottom: 2rem;
}
.formSubHeaderText{
    @extend .text;
    margin-bottom: 4rem;
}
.loginInputContainer{
    display: flex;
    width: 100%;
    border-radius: 0.8rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #70707093;
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.inputIcon{
    width: 30px;
    height: 30px;
    margin-right: 1.3rem;
}
.showPassword{
    margin: 0;
    font-size: 1.3rem;
    margin-left: 1.3rem;
    color: #70707093;
    cursor: pointer;
}
.showPassword:hover{
    color: black;
}
.loginInput{
    flex: 1;
    padding: 1.5rem 0rem;
    border-top-right-radius:inherit;
    border-bottom-right-radius: inherit;
    font-size: 1.3rem;
    border: none;
    outline-color: transparent;
    // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.loginInput::placeholder{
    font-size: 1.5rem;
}
.loginButton{
    border: none;
    background: #998DCE;
    padding: 1.3rem 3rem;
    border-radius: 0.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
    cursor: pointer;
}
.loginButton:hover{
    background: rgb(102, 82, 190);
}
.resendVerificationContainer{
    @extend .loginInputContainer;
    padding: 0.2rem;
    margin: 0;
    margin-bottom: 2rem;
}
.successLargeText{
    @extend .formHeaderText;
    text-align: center;
    margin-bottom: 2rem;
}
.successMiniText{
    @extend .formHeaderText;
    font-size: 1.5rem;
    font-weight: 500;
}
.resendButton{
    text-decoration: none;
    font-size: 1.5rem;
    border: 1px solid #172B4D;
    width: fit-content;
    font-weight: normal;
    padding: 1rem;
    border-radius: 0.3rem;
    align-self: center;
    background-color: transparent;
}
.resendButton:hover{
    color: white;
    background-color: #172B4D;
}

.invalidInput{
    border: 1.5px solid red;
}
.invalidInput:focus{
    border: 1.5px solid red;
}

.invalidPassword {
    color: red;
    overflow-wrap: break-word;
    max-width: 400px;
    font-size: 12px;
}
.invalidPassword:focus {
    color: red;
    overflow-wrap: break-word;
    max-width: 400px;
    font-size: 12px;
}

.validPassword {
    display: none;
}

.validationMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    span{
        font-size: 12px;
    }
}

.errorIcon {
    color: red;
    font-size: 13px;
    margin-left: 5px;
}

.successIcon {
    color: green;
    font-size: 13px;
    margin-left: 5px;
}