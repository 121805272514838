.notification{
    position: fixed;
    color: white;
    border-radius: 0.5rem;
    padding: 2rem 4rem;
    z-index: 10;
    min-width: 500px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    top: -100%;
    transition: top 300ms ease-in-out;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
.showNotification{
    top: 10px;
}
.success{
    background-color: #2ECC71;
}
.failure{
    background-color: #E74C3C;
}
.warning{
    background-color: #F1C40F;
}